/* eslint-disable no-lone-blocks */
{
    /* Ugly should move hardcoded values to UITableHeaders constants file */
}
export const LeaderBoardGenTableHeaders = [
    "Location",
    "Status",
    "View Mode",
    "Layout",
    "Transition Timer",
    "Actions",
];

export const UsersGenTableHeaders = ["Username", "Password", "Actions"];

{
    /* Ugly should move hardcoded values to UITableHeaders constants file */
}
export const LeaderBoardOpsTableHeaders = ["Team", "Room", "score", "Actions"];

{
    /* Ugly should move hardcoded values to UITableHeaders constants file */
}
export const LeaderBoardOpsStores = ["dubai", "pristina", "Actions"];
