import {
    HANDLE_TOKEN_SUCCESS,
    HANDLE_INPUT_SUCCESS,
    HANDLE_SUBMIT_SUCCESS,
    HANDLE_SUBMIT_FAILED,
    GET_CLIENTS_SUCCESS,
    SELECTED_CLIENT,
    SELECTED_ROOM,
    GET_APPOINTMENTS_SUCCESS,
    APPOINTMENTS_NOT_FOUND,
    INITIALIZATION_APP,
    GET_CLIENT_ROOMS_SUCCESS,
    MAKE_BOOKING_SUCCESS,
    BOOK,
    HOMEPAGE,
    CONTROL_PANEL,
    ADMIN_PANEL,
    // GET_LEADERBOARDS,
    OPEN_LDB_MODAL,
    CLOSE_LDB_MODAL,
} from "./actions";

const initialState = {
    applicationState: "loginForm",
    user: "",
    clientRooms: "",
    selectedRoom: "",
    userLastSync: "",
    formInputs: {
        username: "",
        password: "",
    },
    errorMessage: "",
    modal: { data: [], isOpen: false },
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case HANDLE_TOKEN_SUCCESS:
            return {
                ...state,
                applicationState: action.payload.applicationState,
                user: action.payload.token,
            };
        case HANDLE_INPUT_SUCCESS:
            return {
                ...state,
                formInputs: action.payload,
                errorMessage: "",
            };
        case HANDLE_SUBMIT_SUCCESS:
            return {
                ...state,
                applicationState: action.payload.applicationState,
                user: action.payload.username,
                errorMessage: "",
            };
        case GET_CLIENTS_SUCCESS:
            return {
                ...state,
                clients: action.payload,
                errorMessage: "",
            };
        case GET_CLIENT_ROOMS_SUCCESS:
            return {
                ...state,
                clientRooms: action.payload,
            };
        case SELECTED_CLIENT:
            return {
                ...state,
                selectedClient: action.payload,
                errorMessage: "",
                userLastSync: "",
                appointments: "",
                totalTeams: "",
                totalPlayers: "",
                test: "",
            };
        case SELECTED_ROOM:
            return {
                ...state,
                selectedRoom: action.payload,
            };
        case GET_APPOINTMENTS_SUCCESS:
            return {
                ...state,
                appointments: action.payload.newAppointments,
                test: action.payload.test,
                userLastSync: action.payload.sync,
                userLastSyncTime: action.payload.time,
                totalTeams: action.payload.totalTeams,
                totalPlayers: action.payload.totalPlayers,
                errorMessage: "",
            };
        case APPOINTMENTS_NOT_FOUND:
            return {
                ...state,
                appointments: "",
                totalTeams: "",
                totalPlayers: "",
                test: "",
                errorMessage: "No results!",
            };
        case HANDLE_SUBMIT_FAILED:
            return {
                ...state,
                errorMessage: "Invalid Username or Password!",
            };
        case BOOK:
            return {
                ...state,
                applicationState: "bookingForm",
                appointments: "",
                errorMessage: "",
            };
        case MAKE_BOOKING_SUCCESS:
            return {
                ...state,
                applicationState: "userPanel",
                appointments: "",
                errorMessage: "",
            };
        case HOMEPAGE:
            return {
                ...state,
                applicationState: "userPanel",
            };
        case CONTROL_PANEL:
            return {
                ...state,
                applicationState: "controlPanel",
            };
        case ADMIN_PANEL:
            return {
                ...state,
                applicationState: "adminPanel",
            };
        case INITIALIZATION_APP:
            return {
                applicationState: "loginForm",
                user: "",
                userLastSync: "",
                formInputs: {
                    username: "",
                    password: "",
                },
                errorMessage: "",
            };
        case OPEN_LDB_MODAL:
            return {
                ...state,
                modal: { isOpen: true, data: { ...action.payload } },
            };
        case CLOSE_LDB_MODAL:
            return { ...state, modal: { isOpen: false, data: [] } };
        default:
            return state;
    }
};

export default reducer;
