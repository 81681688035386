import { useState, useEffect } from "react";
import "./clientRoomsTab.scss";
import { connect } from "react-redux";
import { selectRoom } from "../../../../models/actions";
import PropTypes from "prop-types";
import { getClientRooms } from "../../../../services/autohelp";

const ClientRoomsTab = ({ client, selectRoom }) => {
    const [rooms, setRooms] = useState([]);
    const getRooms = async () => {
        const clientRooms = await getClientRooms(client);
        setRooms(clientRooms.rooms);
    };
    useEffect(() => {
        getRooms();
    }, []);

    return (
        <select
            name='room'
            id='room'
            className='clientRoomsTab__select'
            onChange={(e) => selectRoom(e.target.value)}>
            <option value=''>Select Escape Room</option>
            {rooms &&
                rooms.map((client, index) =>
                    client ? (
                        <option
                            key={index}
                            value={client}>
                            {client.toUpperCase().replace(/_/g, " ")}
                        </option>
                    ) : (
                        <></>
                    )
                )}
        </select>
    );
};

const mapStateToProps = (state) => ({
    rooms: state.clientRooms,
});

const mapDispatchToProps = (dispatch) => ({
    selectRoom: (payload) => dispatch(selectRoom(payload)),
});

ClientRoomsTab.propTypes = {
    rooms: PropTypes.array,
    client: PropTypes.string,
    selectRoom: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientRoomsTab);
