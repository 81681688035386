import { useEffect, useState } from "react";
import "./bookingForm.scss";
import { connect } from "react-redux";
import Calendar from "../Calendar/Calendar";
import ClientRoomsTab from "./ClientRoomsTab";
import TimePicker from "react-time-picker";
import PropTypes from "prop-types";
import { createBooking } from "../../../services/autohelp";

const BookingForm = ({ client, selectedRoom, players }) => {
    const [numberOfPlayers, setNumberOfPlayers] = useState("");
    const [showCalendar, setShowCalendar] = useState(false);
    const [time, setTime] = useState("10:30:00");
    const [test, setTest] = useState(false);

    useEffect(() => {
        if (selectedRoom !== "" && numberOfPlayers !== "") {
            setShowCalendar(true);
        } else {
            setShowCalendar(false);
        }
    }, [selectedRoom, numberOfPlayers, time]);

    const handleCheckbox = (e) => {
        const { checked } = e.target;
        setTest(checked);
    };

    const sendBookingData = (data) => {
        let location = client.replace("go", "");
        if (test) {
            location = "TEST";
        }
        let sendData = {
            customerID: data.customerID,
            roomName: selectedRoom,
            location,
            numberOfPlayers,
            date: data.date,
            hour: time,
        };
        createBooking(sendData);
    };

    const createPlayersInput = (players) => (
        <select
            name='players'
            id='players'
            className='bookingForm__players'
            onChange={(e) => setNumberOfPlayers(e.currentTarget.value)}>
            <option value=''>Select Number Of Players</option>
            {players.map((item, index) => (
                <option
                    key={index}
                    value={item}>
                    {item} Players
                </option>
            ))}
        </select>
    );

    return (
        <>
            <div className='bookingForm__container'>
                <h3>Make a booking!</h3>
                {createPlayersInput(players)}
                <ClientRoomsTab client={client} />
                <TimePicker
                    onChange={setTime}
                    value={time}
                />
                <div className='bookingForm__checkbox'>
                    <label htmlFor=''>Test</label>
                    <input
                        type='checkbox'
                        name='test'
                        id='test'
                        onChange={(e) => handleCheckbox(e)}
                    />
                </div>

                {showCalendar && (
                    <Calendar
                        client={client}
                        singleDate={true}
                        sendData={sendBookingData}
                    />
                )}
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    client: state.user,
    players: [2, 3, 4, 5, 6, 7, 8, 9, 10],
    selectedRoom: state.selectedRoom,
    errorMessage: state.errorMessage,
});

BookingForm.propTypes = {
    client: PropTypes.string,
    selectedRoom: PropTypes.string,
    players: PropTypes.array,
    makeBooking: PropTypes.func,
};

export default connect(mapStateToProps)(BookingForm);
