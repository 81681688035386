import "./controlPanel.scss";
import { connect } from "react-redux";
import { getClients } from "../../../models/actions";
import LeaderBoardGenTable from "../../LeaderBoardGenTable";
import LeaderBoardOpsTable from "../../LeaderBoardOpsTable";
import UsersGenTable from "../../UsersGenTable/UsersGenTable";
import SettingsContainer from "../../../components/SettingsContainer";
import HeaderText from "../../../components/SettingsContainer/Header";
import PropTypes from "prop-types";

const ControlPanel = ({ userName }) => {
    return (
        <>
            <HeaderText
                title={userName.toUpperCase()}
                caption={"Management Menu"}
            />
            <div className='controlPanel__settings'>
                <div>
                    <SettingsContainer title='LEADER BOARDS - GENERAL'>
                        <LeaderBoardGenTable />
                    </SettingsContainer>
                    <SettingsContainer title='LEADER BOARDS - OPERATIONS'>
                        <LeaderBoardOpsTable />
                    </SettingsContainer>
                    <SettingsContainer title='USER - OPERATIONS'>
                        <UsersGenTable />
                    </SettingsContainer>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    errorMessage: state.errorMessage,
    userName: state.user,
});

const mapDispatchToProps = (dispatch) => ({
    getClients: (payload) => dispatch(getClients(payload)),
});

ControlPanel.propTypes = {
    userName: PropTypes.string,
    getClients: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ControlPanel);
