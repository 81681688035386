import { useEffect, useState } from "react";
import { connect } from "react-redux";
import leaderBoardService from "../../controllers/leaderboard";
import { LeaderBoardGenTableHeaders } from "../../constants";
import Filter from "../Filter/Filter";
import * as actions from "../../models/actions";
import { Button, TableCell, TableRow } from "@mui/material";
import SettingsTable from "../SettingsContainer/SettingsTable";
import { SignalWifi0BarOutlined } from "@mui/icons-material";
import CreateLocationModal from "../../models/CreateLocationsModal";

/**
 * @name LeaderBoardTable
 * @return -> Table with all leader board settings
 *
 */
const LeaderBoardGenTable = () => {
    const [boards, setBoards] = useState([]);
    const [view, setView] = useState("");
    const [layout, setLayout] = useState("");
    const [status, setStatus] = useState(false);
    const [tTimer, setTTimer] = useState(0);
    const [changesTracker, setChangesTracker] = useState({});
    const [showCreateLocationModal, setShowCreateLocationModal] =
        useState(false);
    const get = async () => setBoards(await leaderBoardService.init());

    useEffect(() => {
        get();
    }, []);

    const resetData = () => {
        setView("");
        setLayout("");
        setStatus(false);
        setTTimer(0);
    };

    const handleFilterChange = (location, newValue, type) => {
        switch (type) {
            case "status":
                setStatus(newValue);
                break;
            case "viewMode":
                setView(newValue);
                break;
            case "layout":
                setLayout(newValue);
                break;
            case "timer":
                setTTimer(newValue);
                break;
            default:
                break;
        }

        setChangesTracker((prev) => ({
            ...prev,
            [location]: { ...prev[location], [type]: newValue },
        }));
    };

    const submit = (location) => {
        const board = boards.find((board) => board.location === location);
        const viewMode = view ? view : board.viewMode;
        const layoutMode = layout ? layout : board.layoutMode;
        const timer = tTimer ? tTimer : board.timer;
        const isEnabled = status
            ? status === "enabled"
                ? 1
                : 0
            : board.enabled;

        // Ugly this message must be in a UIBlockMessages constants file
        if (
            window.confirm("Are you sure you want to change these settings :)")
        ) {
            try {
                leaderBoardService.setLeaderBoardConfig({
                    location,
                    viewMode,
                    layoutMode,
                    isEnabled,
                    timer,
                });
                get();
            } catch (error) {
                window.alert("An Error Occured :(");
            }
        }
        setChangesTracker((prev) => ({ ...prev, [location]: {} }));
        return resetData();
    };

    const handleCreateLocationModal = () => {
        setShowCreateLocationModal(!showCreateLocationModal);
    };

    return (
        <SettingsTable
            tableHeaders={LeaderBoardGenTableHeaders}
            alignment={true}>
            {boards.map((row) => (
                <TableRow
                    key={row.location}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell
                        component='th'
                        scope='row'>
                        {row.location}
                    </TableCell>
                    <TableCell
                        align='right'
                        component='th'
                        scope='row'>
                        <SignalWifi0BarOutlined
                            color={row.enabled === "1" ? "primary" : "error"}
                        />
                        <Filter
                            type='status'
                            defaultOpt={
                                row.enabled !== "1" ? "disabled" : "enabled"
                            }
                            submit={(newStatus) =>
                                handleFilterChange(
                                    row.location,
                                    newStatus,
                                    "status"
                                )
                            }
                        />
                    </TableCell>
                    <TableCell align='right'>
                        <Filter
                            type='viewMode'
                            defaultOpt={row.viewMode}
                            submit={(newView) =>
                                handleFilterChange(
                                    row.location,
                                    newView,
                                    "viewMode"
                                )
                            }
                        />
                    </TableCell>
                    <TableCell align='right'>
                        <Filter
                            type='layout'
                            defaultOpt={row.layoutMode}
                            submit={(newLayout) =>
                                handleFilterChange(
                                    row.location,
                                    newLayout,
                                    "layout"
                                )
                            }
                        />
                    </TableCell>
                    <TableCell align='right'>
                        <Filter
                            type='timer'
                            defaultOpt={row.timer}
                            submit={(newTimer) =>
                                handleFilterChange(
                                    row.location,
                                    newTimer,
                                    "timer"
                                )
                            }
                        />
                    </TableCell>
                    <TableCell align='right'>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={() => submit(row.location)}
                            disabled={
                                !changesTracker[row.location] ||
                                Object.keys(changesTracker[row.location])
                                    .length === 0
                            }>
                            Save
                        </Button>
                    </TableCell>
                </TableRow>
            ))}
            <div
                className='create_user_modal'
                onClick={handleCreateLocationModal}>
                CREATE LOCATION
            </div>
            <CreateLocationModal
                showCreateLocationModal={showCreateLocationModal}
                handleClose={handleCreateLocationModal}
                getBoards={get}
                boards={boards}
            />
        </SettingsTable>
    );
};

const mapStateToProps = (state) => ({
    errorMessage: state.errorMessage,
    leaderBoards: state.leaderBoards,
});

const mapDispatchToProps = (dispatch) => ({
    openModal: (payload) => dispatch(actions.openLDBModal(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LeaderBoardGenTable);
