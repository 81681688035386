import { useEffect } from "react";
import "./adminPanel.scss";
import ClientsSelectionTab from "./ClientsSelectionTab";
import Calendar from "../Calendar/Calendar";
import { connect } from "react-redux";
import { getClients, fetchAppointments } from "../../../models/actions";
import Appointments from "../Appointments/Appointments";
import PropTypes from "prop-types";
import HeaderText from "../../../components/SettingsContainer/Header";

const AdminPanel = ({
    userName,
    getClients,
    selectedClient,
    fetchAppointments,
    appointments,
    errorMessage,
}) => {
    useEffect(() => {
        getClients();
    }, [getClients]);
    return (
        <>
            <HeaderText
                title={userName.toUpperCase()}
                caption={"statistics menu"}
            />
            <div className='adminPanel__calendar__container'>
                {userName === "gameoveradmin" && <ClientsSelectionTab />}
                <Calendar
                    client={selectedClient}
                    fetchAppointments={fetchAppointments}
                />
            </div>
            <div className='adminPanel__appointments__container'>
                {appointments && <Appointments />}
                <p style={{ color: "red" }}>{errorMessage}</p>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    userName: state.user,
    selectedClient: state.selectedClient,
    appointments: state.appointments,
    errorMessage: state.errorMessage,
});

const mapDispatchToProps = (dispatch) => ({
    getClients: (payload) => dispatch(getClients(payload)),
    fetchAppointments: (payload) => dispatch(fetchAppointments(payload)),
});

AdminPanel.propTypes = {
    userName: PropTypes.string,
    getClients: PropTypes.func,
    selectedClient: PropTypes.string,
    fetchAppointments: PropTypes.func,
    appointments: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
        PropTypes.string,
    ]),
    errorMessage: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminPanel);
