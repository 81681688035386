import { useEffect, useState } from "react";
import Input from "./Input/Input";
import "./loginForm.scss";
import { connect } from "react-redux";
import { handleSubmit } from "../../../models/actions";
import AhsLogo from "../../../assets/ahs_logo.png";
import PropTypes from "prop-types";

const LoginForm = ({ username, password, handleSubmit, errorMessage }) => {
    const [submit, setSubmit] = useState(false);
    useEffect(() => {
        if (username.length > 4 && password.length > 4) {
            setSubmit(true);
        } else {
            setSubmit(false);
        }
    }, [username, password]);

    return (
        <div className='loginForm__container'>
            <img
                src={AhsLogo}
                alt=''
            />
            <form
                onSubmit={(e) => {
                    e.preventDefault(e);
                    handleSubmit({ username, password });
                }}>
                <div className='loginForm__form__container'>
                    <Input
                        name='username'
                        value={username}
                    />
                    <Input
                        name='password'
                        value={password}
                    />
                    <button
                        name='Login'
                        type='submit'
                        className='loginForm__btn__submit'
                        disabled={!submit}>
                        LOGIN
                    </button>
                    <p style={{ color: "red" }}>{errorMessage}</p>
                </div>
            </form>
        </div>
    );
};

const mapStateToProps = (state) => ({
    username: state.formInputs.username,
    password: state.formInputs.password,
    errorMessage: state.errorMessage,
});

const mapDispatchToProps = (dispatch) => ({
    handleSubmit: (payload) => dispatch(handleSubmit(payload)),
});

LoginForm.propTypes = {
    username: PropTypes.string,
    password: PropTypes.string,
    handleSubmit: PropTypes.func,
    errorMessage: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
