import { useEffect } from "react";
import { connect } from "react-redux";
import LoginForm from "./LoginForm";
import AdminPanel from "./AdminPanel";
import UserPanel from "./UserPanel";
import BookingForm from "./BookingForm";
import Logo from "../../assets/logo.png";
import ControlPanel from "./ControlPanel";
import "./application.scss";
import {
    handleTokenValidator,
    logOut,
    book,
    homepage,
    goToControlPanel,
    goToAdminPanel,
} from "../../models/actions";
import PropTypes from "prop-types";

//TODO Use Context Hook or simple hook to track data and remove Redux no need to persist this data
const Application = ({
    applicationState,
    handleTokenValidator,
    logOut,
    book,
    homepage,
    goToControlPanel,
    goToAdminPanel,
}) => {
    useEffect(() => {
        handleTokenValidator();
    }, [handleTokenValidator]);

    return (
        <div className='application__container'>
            <div className='application__logo'>
                <img
                    width='80'
                    src={Logo}
                    alt=''
                />
                <div>
                    {applicationState === "userPanel" && (
                        <button
                            className='application__btn'
                            onClick={book}>
                            Book
                        </button>
                    )}
                    {applicationState === "bookingForm" && (
                        <button
                            className='application__btn'
                            onClick={homepage}>
                            Homepage
                        </button>
                    )}
                    {applicationState !== "loginForm" && (
                        <button
                            className='application__btn'
                            style={{ backgroundColor: "  #696a6c" }}
                            onClick={logOut}>
                            Log out
                        </button>
                    )}
                    {applicationState === "controlPanel" && (
                        <button
                            className='application__btn'
                            style={{ backgroundColor: "  #696a6c" }}
                            onClick={goToAdminPanel}>
                            Admin Panel
                        </button>
                    )}
                    {applicationState === "adminPanel" && (
                        <button
                            className='application__btn'
                            style={{ backgroundColor: "  #dd1936" }}
                            onClick={goToControlPanel}>
                            Control Panel
                        </button>
                    )}
                </div>
            </div>
            <div className='application__content'>
                {applicationState === "loginForm" && <LoginForm />}
                {applicationState === "controlPanel" && <ControlPanel />}
                {applicationState === "adminPanel" && <AdminPanel />}
                {applicationState === "userPanel" && <UserPanel />}
                {applicationState === "bookingForm" && <BookingForm />}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    applicationState: state.applicationState,
});

const mapDispatchToProps = (dispatch) => ({
    handleTokenValidator: () => dispatch(handleTokenValidator()),
    logOut: () => dispatch(logOut()),
    book: () => dispatch(book()),
    homepage: () => dispatch(homepage()),
    goToControlPanel: () => dispatch(goToControlPanel()),
    goToAdminPanel: () => dispatch(goToAdminPanel()),
});

Application.propTypes = {
    applicationState: PropTypes.string,
    handleTokenValidator: PropTypes.func,
    logOut: PropTypes.func,
    book: PropTypes.func,
    homepage: PropTypes.func,
    goToControlPanel: PropTypes.func,
    goToAdminPanel: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Application);
