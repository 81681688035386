import { useEffect } from "react";
import "./userPanel.scss";
import { connect } from "react-redux";
import { getClientRooms, fetchAppointments } from "../../../models/actions";
import Calendar from "../Calendar/Calendar";
import Appointments from "../Appointments/Appointments";
import PropTypes from "prop-types";

const UserPanel = ({
    userName,
    fetchAppointments,
    appointments,
    getClientRooms,
    errorMessage,
}) => {
    useEffect(() => {
        getClientRooms();
    }, [getClientRooms]);
    return (
        <>
            <h2 className='userPanel__header'>Hi {userName.toUpperCase()}!</h2>
            <div className='userPanel__calendar__container'>
                <Calendar
                    client={userName}
                    fetchAppointments={fetchAppointments}
                />
            </div>
            <div className='userPanel__appointments__container'>
                {appointments && <Appointments />}
                <p style={{ color: "red" }}>{errorMessage}</p>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    userName: state.user,
    appointments: state.appointments,
    errorMessage: state.errorMessage,
});

const mapDispatchToProps = (dispatch) => ({
    getClientRooms: (payload) => dispatch(getClientRooms(payload)),
    fetchAppointments: (payload) => dispatch(fetchAppointments(payload)),
});

UserPanel.propTypes = {
    userName: PropTypes.string,
    fetchAppointments: PropTypes.func,
    appointments: PropTypes.any,
    getClientRooms: PropTypes.func,
    errorMessage: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserPanel);
