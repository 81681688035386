import { connect } from "react-redux";
import "./input.scss";
import { handleInputEvent } from "../../../../models/actions";
import PropTypes from "prop-types";

const Input = ({ name, value, handleInputEvent }) => (
    <div className='input__div'>
        <input
            name={name}
            className='input'
            type={name === "password" ? "password" : "text"}
            placeholder={name}
            value={value}
            onChange={(e) => handleInputEvent(e.target)}
        />
    </div>
);

// const mapStateToProps = (state) => ({});
const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
    handleInputEvent: (payload) => dispatch(handleInputEvent(payload)),
});

Input.propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    handleInputEvent: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Input);
