import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import { deleteData } from "../constants/LeaderBoardOpsTable";
import { deleteUser } from "../services/autohelp";
import leaderBoardService from "../controllers/leaderboard";
import PropTypes from "prop-types";

const ConfirmDeleteModal = ({
    open,
    handleClose,
    selectedTeam,
    getTeams,
    getUsers,
    selectedUser,
    isToDeleteUser,
}) => {
    const handleDelete = async () => {
        if (selectedTeam) {
            try {
                const res = await leaderBoardService.deleteTeam(
                    deleteData(selectedTeam)
                );
                if (res === "Success") {
                    await getTeams(selectedUser);
                    handleClose();
                    selectedUser();
                    await getUsers();
                } else {
                    window.alert("Something went wrong");
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    const handleDeleteUser = async () => {
        try {
            const res = await deleteUser(selectedUser.id);
            if (res.status === "success") {
                await getUsers();
                handleClose();
            } else {
                window.alert("Something went wrong");
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'>
            <DialogTitle id='alert-dialog-title'>{`Delete ${
                isToDeleteUser ? "User" : "Team"
            } ?`}</DialogTitle>
            <DialogContent>
                <div>
                    Are you sure you want to delete this{" "}
                    {isToDeleteUser ? "user" : "team"}?
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    color='primary'>
                    Cancel
                </Button>
                <Button
                    onClick={isToDeleteUser ? handleDeleteUser : handleDelete}
                    color='secondary'>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
};

ConfirmDeleteModal.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    selectedTeam: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object,
    ]),
    getTeams: PropTypes.func,
    getUsers: PropTypes.func,
    selectedUser: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object,
    ]),
    isToDeleteUser: PropTypes.bool,
};

export default ConfirmDeleteModal;
