import { useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from "@mui/material";
import {
    handleUsernameChange,
    handlePasswordChange,
} from "../helpers/CreateUser.helpers";
import { createUser, updateUser } from "../services/autohelp";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import PropTypes from "prop-types";

const CreateUserModal = ({
    open,
    handleClose,
    isToEdit,
    selectedUser,
    users,
    getUsers,
}) => {
    const [newUsername, setNewUsername] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [usernameError, setUsernameError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
    const [shouldShowPassword, setShouldShowPassword] = useState(false);

    const handleSaveClick = async () => {
        if (!usernameError && !passwordError) {
            try {
                const res = await createUser(newUsername, newPassword);
                if (res.status === "success") {
                    await getUsers();
                    handleClose();
                }
            } catch (err) {
                console.log(`An Error Occurred : ${err}`);
            }
        }
    };

    const handleEditUser = async () => {
        if (isToEdit) {
            try {
                const res = await updateUser(
                    newUsername,
                    newPassword,
                    selectedUser.id
                );
                if (res.status === "success") {
                    await getUsers();
                    handleClose();
                }
            } catch (err) {
                console.log(`An Error Occurred : ${err}`);
            }
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby='form-dialog-title'>
            <DialogTitle id='form-dialog-title'>{`${
                isToEdit ? "Edit" : "Create"
            } User`}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin='dense'
                    placeholder='Username'
                    id='username'
                    label='Username'
                    type='text'
                    value={newUsername}
                    onChange={(event) =>
                        handleUsernameChange(
                            event,
                            setNewUsername,
                            setUsernameError,
                            users
                        )
                    }
                    fullWidth
                    error={!!usernameError}
                    helperText={usernameError}
                />
                <div className='password_text_input'>
                    <TextField
                        type={shouldShowPassword ? "password" : "text"}
                        autoFocus
                        margin='dense'
                        label='Password'
                        placeholder='Password'
                        id='password'
                        value={newPassword}
                        onChange={(event) =>
                            handlePasswordChange(
                                event,
                                setNewPassword,
                                setPasswordError,
                                setIsSaveButtonEnabled,
                                newUsername
                            )
                        }
                        fullWidth
                        error={!!passwordError}
                        helperText={passwordError}
                    />
                    {shouldShowPassword ? (
                        <VisibilityOffIcon
                            onClick={() =>
                                setShouldShowPassword(!shouldShowPassword)
                            }
                        />
                    ) : (
                        <VisibilityIcon
                            onClick={() =>
                                setShouldShowPassword(!shouldShowPassword)
                            }
                        />
                    )}
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    color='secondary'>
                    Cancel
                </Button>
                <Button
                    onClick={isToEdit ? handleEditUser : handleSaveClick}
                    color='primary'
                    disabled={!isSaveButtonEnabled}>
                    {isToEdit ? "Save Changes" : "Create User"}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

CreateUserModal.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.any,
    isToEdit: PropTypes.bool,
    selectedUser: PropTypes.any,
    users: PropTypes.any,
    getUsers: PropTypes.any,
};

export default CreateUserModal;
