export const CHECK_IF_TOKEN_EXIST = "CHECK_IF_TOKEN_EXIST";
export const HANDLE_TOKEN = "HANDLE_TOKEN";
export const HANDLE_TOKEN_SUCCESS = "HANDLE_TOKEN_SUCCESS";
export const HANDLE_INPUT_EVENT = "HANDLE_INPUT_EVENT";
export const HANDLE_INPUT_SUCCESS = "HANDLE_INPUT_SUCCESS";
export const HANDLE_SUBMIT = "HANDLE_SUBMIT";
export const HANDLE_SUBMIT_SUCCESS = "HANDLE_SUBMIT_SUCCESS";
export const HANDLE_SUBMIT_FAILED = "HANDLE_SUBMIT_FAILED";
export const GET_CLIENTS = "GET_CLIENTS";
export const GET_CLIENTS_SUCCESS = "GET_CLIENTS_SUCCESS";
export const GET_CLIENT_ROOMS = "GET_CLIENT_ROOMS";
export const GET_CLIENT_ROOMS_SUCCESS = "GET_CLIENT_ROOMS_SUCCESS";
export const SELECTED_CLIENT = "SELECTED_CLIENT";
export const SELECTED_ROOM = "SELECTED_ROOM";
export const GET_APPOINTMENTS = "GET_APPOINTMENTS";
export const GET_APPOINTMENTS_SUCCESS = "GET_APPOINTMENTS_SUCCESS";
export const GET_APPOINTMENTS_PROCESS = "GET_APPOINTMENTS_PROCESS";
export const MAKE_BOOKING = "MAKE_BOOKING";
export const MAKE_BOOKING_SUCCESS = "MAKE_BOOKING_SUCCESS";
export const APPOINTMENTS_NOT_FOUND = "APPOINTMENTS_NOT_FOUND";
export const FETCH_USER_SYNC = "FETCH_USER_SYNC";
export const LOG_OUT = "LOG_OUT";
export const CONTROL_PANEL = "CONTROL_PANEL";
export const ADMIN_PANEL = "ADMIN_PANEL";
export const BOOK = "BOOK";
export const HOMEPAGE = "HOMEPAGE";
export const INITIALIZATION_APP = "INITIALIZATION_APP";
export const INIT_LEADERBOARDS = "INIT_LEADERBOARDS";
export const GET_LEADERBOARDS = "GET_LEADERBOARDS";
export const OPEN_LDB_MODAL = "OPEN_LDB_MODAL";
export const CLOSE_LDB_MODAL = "CLOSE_LDB_MODAL";

export const goToControlPanel = () => ({
    type: CONTROL_PANEL,
    payload: "controlPanel",
});
export const goToAdminPanel = () => ({
    type: ADMIN_PANEL,
    payload: "adminPanel",
});
export const checkIfTokenExists = (payload) => ({
    type: CHECK_IF_TOKEN_EXIST,
    payload,
});

export const handleTokenValidator = (payload) => ({
    type: HANDLE_TOKEN,
    payload,
});

export const handleInputEvent = (payload) => ({
    type: HANDLE_INPUT_EVENT,
    payload,
});

export const handleSubmit = (payload) => ({
    type: HANDLE_SUBMIT,
    payload,
});

export const getClients = (payload) => ({
    type: GET_CLIENTS,
    payload,
});

export const getClientRooms = (payload) => ({
    type: GET_CLIENT_ROOMS,
    payload,
});

export const selectClient = (payload) => ({
    type: SELECTED_CLIENT,
    payload,
});

export const selectRoom = (payload) => ({
    type: SELECTED_ROOM,
    payload,
});

export const fetchAppointments = (payload) => ({
    type: GET_APPOINTMENTS,
    payload,
});

export const makeBooking = (payload) => ({
    type: MAKE_BOOKING,
    payload,
});

export const logOut = () => ({
    type: LOG_OUT,
});

export const book = () => ({
    type: BOOK,
});

export const homepage = () => ({
    type: HOMEPAGE,
});

export const initLeaderBoards = (payload) => ({
    type: INIT_LEADERBOARDS,
    payload,
});

export const getLeaderBoards = (payload) => ({
    type: GET_LEADERBOARDS,
    payload,
});
export const openLDBModal = (payload) => ({
    type: OPEN_LDB_MODAL,
    payload,
});
export const closeLDBModal = (payload) => ({
    type: CLOSE_LDB_MODAL,
    payload,
});
