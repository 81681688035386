import { useEffect, useState } from "react";
import { connect } from "react-redux";
import leaderBoardService from "../../controllers/leaderboard";
import { LeaderBoardOpsTableHeaders } from "../../constants";
import * as actions from "../../models/actions";
import { Button, TableCell, TableRow, Tabs, Tab } from "@mui/material";
import SettingsTable from "../SettingsContainer/SettingsTable";
import EditTeamNameModal from "../../models/EditTeamNameModal";
import ConfirmDeleteModal from "../../models/ConfirmTeamDeleteModal";
import "./index.scss";

/**
 * @name LeaderBoardOpsTable
 * @return -> Table with all leader board settings
 */

const LeaderBoardOpsTable = () => {
    const [teams, setTeams] = useState([]);
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState("");
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const getUsers = async () => {
        const users = await leaderBoardService.init();
        const mapped = users.map((user) => user.location);
        setUsers(mapped);
        setSelectedUser(mapped[0]);
    };

    const getTeams = async (user) => {
        setTeams(await leaderBoardService.getTeams(user));
    };

    useEffect(() => {
        getUsers();
    }, []);

    useEffect(() => {
        if (selectedUser) {
            getTeams(selectedUser);
        }
    }, [selectedUser]);

    const handleDeleteClick = (team) => {
        setSelectedTeam(team);
        setShowDeleteModal(true);
        setSelectedUser(selectedUser);
    };

    const handleClose = () => {
        setSelectedTeam(null);
        setIsEditing(false);
        setShowDeleteModal(false);
    };

    const openInput = (team) => {
        setSelectedTeam(team);
        setIsEditing(true);
    };

    const handleTabChange = (event, newValue) => {
        setSelectedUser(users[newValue]);
    };

    return (
        <>
            <Tabs
                value={
                    users.includes(selectedUser)
                        ? users.indexOf(selectedUser)
                        : 0
                }
                onChange={handleTabChange}
                variant='scrollable'
                scrollButtons='auto'
                aria-label='scrollable auto tabs example'>
                {users.map((user, index) => (
                    <Tab
                        label={user}
                        key={index}
                    />
                ))}
            </Tabs>

            <SettingsTable tableHeaders={LeaderBoardOpsTableHeaders}>
                {teams.map((row, index) => (
                    <TableRow
                        key={index}
                        sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                        }}>
                        <TableCell>{row.team_name}</TableCell>
                        <TableCell>{row.room_name}</TableCell>
                        <TableCell>{row.score}</TableCell>
                        <TableCell>
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={() => openInput(row)}>
                                Edit
                            </Button>
                            <Button
                                variant='contained'
                                color='secondary'
                                onClick={() => handleDeleteClick(row)}>
                                Delete
                            </Button>
                        </TableCell>
                    </TableRow>
                ))}
                <EditTeamNameModal
                    open={isEditing}
                    handleClose={handleClose}
                    selectedTeam={selectedTeam}
                    getTeams={getTeams}
                    getUsers={getUsers}
                    selectedUser={selectedUser}
                />
                <ConfirmDeleteModal
                    open={showDeleteModal}
                    handleClose={handleClose}
                    selectedTeam={selectedTeam}
                    getTeams={getTeams}
                    getUsers={getUsers}
                    selectedUser={selectedUser}
                />
            </SettingsTable>
        </>
    );
};

const mapStateToProps = (state) => ({
    errorMessage: state.errorMessage,
    leaderBoards: state.leaderBoards,
});

const mapDispatchToProps = (dispatch) => ({
    openModal: (payload) => dispatch(actions.openLDBModal(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LeaderBoardOpsTable);
