import { jwtDecode } from "jwt-decode";

const tokenValidator = () => {
    const token = localStorage.getItem("token");

    if (token) {
        const decoded = jwtDecode(token);
        return decoded.data.username;
    }
};

export default tokenValidator;
