import { useState } from "react";
import Filter from "../components/Filter/Filter";
import { handleLocationChange } from "../helpers/CreateUser.helpers";
import { DATA } from "../constants/LeaderBoardOpsTable";
import leaderBoardService from "../controllers/leaderboard";
import PropTypes from "prop-types";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TableCell,
    TextField,
} from "@mui/material";

const CreateLocationModal = ({
    showCreateLocationModal,
    handleClose,
    boards,
    getBoards,
}) => {
    const [newLocation, setNewLocation] = useState("");
    const [newLocationError, setNewLocationError] = useState("");
    const [view, setView] = useState(DATA.viewModes[0]);
    const [layout, setLayout] = useState(DATA.layouts[0]);
    const [status, setStatus] = useState(DATA.statuses[0]);
    const [tTimer, setTTimer] = useState(DATA.timers[0]);
    const [isEditing, setIsEditing] = useState(true);
    const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
    const [userWasNotCreated, setUserWasNotCreated] = useState(false);

    const resetData = () => {
        setView(DATA.viewModes[0]);
        setLayout(DATA.layouts[0]);
        setStatus(DATA.statuses[0]);
        setTTimer(DATA.timers[0]);
        setIsEditing(true);
    };

    const handleSaveLocation = async () => {
        if (isEditing) {
            try {
                const res = await leaderBoardService.createLocation(
                    newLocation,
                    status,
                    view,
                    layout,
                    tTimer
                );
                if (res && res === "Success") {
                    getBoards();
                    handleClose();
                    resetData();
                } else {
                    setUserWasNotCreated(true);
                }
            } catch (err) {
                console.log(`An Error Occurred : ${err}`);
            }
        }
    };

    return (
        <Dialog
            open={showCreateLocationModal}
            onClose={handleClose}
            aria-labelledby='form-dialog-title'>
            {!userWasNotCreated && (
                <DialogTitle id='form-dialog-title'>
                    Create Location
                </DialogTitle>
            )}
            <DialogContent className='create_location_options'>
                {!userWasNotCreated && (
                    <>
                        <TextField
                            autoFocus
                            margin='dense'
                            placeholder='Location'
                            id='Location'
                            label='Location'
                            type='text'
                            value={newLocation}
                            onChange={(event) =>
                                handleLocationChange(
                                    event,
                                    setNewLocation,
                                    setNewLocationError,
                                    setIsSaveButtonEnabled,
                                    boards
                                )
                            }
                            fullWidth
                            error={!!newLocationError}
                            helperText={newLocationError}
                        />
                        <TableCell
                            align='right'
                            component='th'
                            scope='row'>
                            <Filter
                                type='status'
                                defaultOpt={status}
                                submit={setStatus}
                                editing={setIsEditing}
                            />
                        </TableCell>
                        <TableCell align='right'>
                            <Filter
                                type='viewMode'
                                defaultOpt={view}
                                submit={setView}
                                editing={setIsEditing}
                            />
                        </TableCell>
                        <TableCell align='right'>
                            <Filter
                                type='layout'
                                defaultOpt={layout}
                                submit={setLayout}
                                editing={setIsEditing}
                            />
                        </TableCell>
                        <TableCell align='right'>
                            <Filter
                                type='timer'
                                defaultOpt={tTimer}
                                submit={setTTimer}
                                editing={setIsEditing}
                            />
                        </TableCell>
                        <DialogActions>
                            <Button
                                onClick={handleClose}
                                color='secondary'>
                                Cancel
                            </Button>
                            <Button
                                onClick={handleSaveLocation}
                                disabled={!isSaveButtonEnabled}
                                color='primary'>
                                Create Location
                            </Button>
                        </DialogActions>{" "}
                    </>
                )}
                {userWasNotCreated &&
                    "Unable to create Location, Contact Support"}
            </DialogContent>
        </Dialog>
    );
};

CreateLocationModal.propTypes = {
    showCreateLocationModal: PropTypes.bool,
    handleClose: PropTypes.any,
    boards: PropTypes.any,
    getBoards: PropTypes.any,
};

export default CreateLocationModal;
