import { useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from "@mui/material";
import leaderBoardService from "../controllers/leaderboard";
import { updateTeamName } from "../constants/LeaderBoardOpsTable";
import PropTypes from "prop-types";

const EditTeamNameModal = ({
    open,
    handleClose,
    selectedTeam,
    getTeams,
    getUsers,
    selectedUser,
}) => {
    const [newTeamName, setNewTeamName] = useState("");
    const [error, setError] = useState("Team name can't be empty");

    const handleTeamNameChange = (event) => {
        const newTeamNameValue = event.target.value;
        setNewTeamName(newTeamNameValue);

        if (newTeamNameValue.trim() === "") {
            setError("Team name can't be empty");
        } else if (newTeamNameValue.length < 4) {
            setError("Team name must be at least 4 characters long");
        } else if (newTeamNameValue.length > 7) {
            setError("Team name shouldn't more the 7 characters long");
        } else {
            setError("");
        }
    };

    const handleSaveClick = async () => {
        if (error === "" && selectedTeam && newTeamName) {
            try {
                const res = await leaderBoardService.updateTeamName(
                    updateTeamName(selectedTeam, newTeamName)
                );
                if (res === "Success") {
                    await getTeams(selectedUser);
                    handleClose();
                    selectedUser();
                    await getUsers();
                } else {
                    window.alert("Something went wrong");
                }
            } catch (err) {
                console.log(`An Error Occured : ${err}`);
            }
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby='form-dialog-title'>
            <DialogTitle id='form-dialog-title'>Edit Team Name</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin='dense'
                    id='teamName'
                    label='Team Name'
                    type='text'
                    value={newTeamName}
                    onChange={handleTeamNameChange}
                    fullWidth
                    error={!!error}
                    helperText={error}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    color='secondary'>
                    Cancel
                </Button>
                <Button
                    onClick={handleSaveClick}
                    color='primary'
                    disabled={!!error}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

EditTeamNameModal.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    selectedTeam: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object,
    ]),
    getTeams: PropTypes.func,
    getUsers: PropTypes.func,
    selectedUser: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object,
    ]),
};

export default EditTeamNameModal;
