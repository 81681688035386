import * as React from "react";
import { Select, FormControl, MenuItem, InputLabel, Box } from "@mui/material";
import { DATA } from "../../constants/LeaderBoardOpsTable";
import PropTypes from "prop-types";

function Filter({ type, defaultOpt, submit }) {
    const [def, setDef] = React.useState(defaultOpt || "");

    const handleChange = (event) => {
        setDef(event.target.value);
        submit(event.target.value);
    };

    const renderOpts = () => {
        if (type === "viewMode") {
            return (
                <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    label='viewMode'
                    value={def}
                    onChange={handleChange}>
                    {DATA.viewModes.map((mode, index) => (
                        <MenuItem
                            key={index}
                            value={mode}>
                            {mode}
                        </MenuItem>
                    ))}
                </Select>
            );
        } else if (type === "layout") {
            return (
                <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    label='layout'
                    value={def}
                    onChange={handleChange}>
                    {DATA.layouts.map((mode, index) => (
                        <MenuItem
                            key={index}
                            value={mode}>
                            {mode}
                        </MenuItem>
                    ))}
                </Select>
            );
        } else if (type === "status") {
            return (
                <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    label='status'
                    value={def}
                    autoWidth
                    onChange={handleChange}>
                    {DATA.statuses.map((mode, index) => (
                        <MenuItem
                            key={index}
                            value={mode}>
                            {mode}
                        </MenuItem>
                    ))}
                </Select>
            );
        } else if (type === "timer") {
            return (
                <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    label='status'
                    value={def}
                    autoWidth
                    onChange={handleChange}>
                    {DATA.timers.map((mode, index) => (
                        <MenuItem
                            key={index}
                            value={mode}>
                            {mode}
                        </MenuItem>
                    ))}
                </Select>
            );
        }

        return <MenuItem>None</MenuItem>;
    };

    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
                <InputLabel id='demo-simple-select-label'>
                    {type.toUpperCase()}
                </InputLabel>
                {renderOpts()}
            </FormControl>
        </Box>
    );
}

Filter.propTypes = {
    type: PropTypes.string,
    defaultOpt: PropTypes.string,
    submit: PropTypes.func,
};

export default Filter;
