export const restructDataAppointment = (dataAppointment) => {
    if (!dataAppointment) {
        return {};
    }

    let data = {};

    // Scenario 1: Aggregated data with roomName, appointmentCount, numberOfPlayers, and customerID
    /*
        {
            "roomName": "ALADDIN",
            "appointmentCount": 10,
            "numberOfPlayers": 4,
            "customerID": "gotoronto"
        },
        ...
    */
    dataAppointment.forEach((obj) => {
        data[obj.roomName] = {
            roomName: obj.roomName,
            appointmentCount: parseInt(obj.appointmentCount, 10),
            numberOfPlayers: parseInt(obj.numberOfPlayers, 10),
        };
    });

    return data;
};
