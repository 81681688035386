import config from "../main-config.json";
const URL = config.leaderboard_api_url;
const Authorization = "xxx-escape-xxx-rooms";
export const fetchAllLeaderBoards = async () => {
    const url = URL + "/config.php";
    try {
        return await fetch(url);
    } catch (e) {
        console.error(e);
    }
};

export const setLeaderBoardConfig = async (settings) => {
    const url = URL + "/setStatus.php";
    const body = JSON.stringify({
        ...settings,
    });
    try {
        return await fetch(url, {
            method: "POST",
            body,
        });
    } catch (e) {
        console.error(e);
    }
};

export const getLeaderBoardConfig = async (location) => {
    const url = URL + "/getStatus.php";
    try {
        return await fetch(`${url}?location=${location}`);
    } catch (e) {
        console.error(e);
    }
};

export const deleteTeam = async (data) => {
    const url = URL + "/deleteTeam.php";
    try {
        return await fetch(
            `${url}?location=${data.location}&team_name=${data.team_name}&room_name=${data.room_name}&Authorization=${Authorization}`
        );
    } catch (e) {
        console.error(e);
    }
};

export const updateTeamName = async (data) => {
    const url = URL + "/updateTeamName.php";
    try {
        return await fetch(
            `${url}?location=${data.location}&old_team_name=${data.old_team_name}&room_name=${data.room_name}&new_team_name=${data.new_team_name}&Authorization=${Authorization}`
        );
    } catch (e) {
        console.error(e);
    }
};

export const getAllTeams = async (location) => {
    const url = URL + `/fetchTeams.php`;
    try {
        return await fetch(`${url}?location=${location}`);
    } catch (e) {
        console.error(e);
    }
};

export const createLocation = async (
    location,
    status,
    viewMode,
    layoutMode,
    transition
) => {
    const url = URL + `/createLocation.php`;
    const body = JSON.stringify({
        location: location,
        status: status,
        viewMode: `${viewMode}`,
        layoutMode: layoutMode,
        transition: transition,
    });
    try {
        return await fetch(url, {
            method: "POST",
            body: body,
        });
    } catch (e) {
        console.error(e);
    }
};
