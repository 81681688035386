import * as LeaderBoardService from "../services/leaderBoard";

class LeaderBoard {
    constructor() {
        this.service = LeaderBoardService;
    }

    async init() {
        const res = await this.service.fetchAllLeaderBoards();
        return await res.json();
    }

    async setLeaderBoardConfig(settings) {
        const res = await this.service.setLeaderBoardConfig(settings);
        return await res.json();
    }

    async getTeams(store) {
        const res = await this.service.getAllTeams(store);
        return await res.json();
    }

    async updateTeamName(settings) {
        const res = await this.service.updateTeamName(settings);
        return await res.json();
    }

    async deleteTeam(settings) {
        const res = await this.service.deleteTeam(settings);
        return await res.json();
    }

    async createUser(username, pass) {
        const res = await this.service.createUser(username, pass);
        return await res.json();
    }

    async createLocation(location, status, viewMode, layoutMode, transition) {
        const res = await this.service.createLocation(
            location,
            status,
            viewMode,
            layoutMode,
            transition
        );
        return await res.json();
    }
}

const leaderBoardService = new LeaderBoard();

export default leaderBoardService;
