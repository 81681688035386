import config from "../main-config.json";
const URL = config.autohelp_api_url;

export const fetchAllLeaderBoards = async () => {
    const url = URL + "/config.php";
    try {
        return await fetch(url);
    } catch (e) {
        console.error(e);
    }
};

export const getClientRooms = async (client) => {
    const url = URL + "/functions/rooms/fetchClientRooms.php";
    const body = JSON.stringify({
        customerID: client,
    });
    try {
        return await fetch(`${url}?customerID=${client}`, {
            method: "POST",
            body,
            redirect: "follow",
        })
            .then((res) => res.json())
            .then((res) => res);
    } catch (e) {
        console.error(e);
    }
};

export const createBooking = async (data) => {
    const url = URL + "/functions/booking/makeAppointment.php";
    const body = JSON.stringify(data);
    try {
        return await fetch(`${url}`, {
            method: "POST",
            body,
            redirect: "follow",
        })
            .then((res) => res.json())
            .then((res) => res);
    } catch (e) {
        console.error(e);
    }
};

export const fetchAllUsers = async () => {
    const url = URL + "/users/fetchAll.php";
    try {
        return await fetch(url, {
            method: "GET",
            redirect: "follow",
        })
            .then((res) => res.json())
            .then((res) => res);
    } catch (e) {
        console.error(e);
    }
};

export const createUser = async (username, password) => {
    const url = URL + `/users/create.php`;
    try {
        return await fetch(`${url}?username=go${username}&pass=go${password}`, {
            method: "POST",
            redirect: "follow",
        })
            .then((res) => res.json())
            .then((res) => res);
    } catch (e) {
        console.error(e);
    }
};

export const updateUser = async (username, password, id) => {
    const url = URL + `/users/update.php`;
    try {
        return await fetch(
            `${url}?username=go${username}&pass=go${password}&id=${id}`,
            {
                method: "POST",
                redirect: "follow",
            }
        )
            .then((res) => res.json())
            .then((res) => res);
    } catch (e) {
        console.error(e);
    }
};

export const deleteUser = async (id) => {
    const url = URL + `/users/delete.php`;

    try {
        return await fetch(`${url}?id=${id}`, {
            method: "POST",
            redirect: "follow",
        })
            .then((res) => res.json())
            .then((res) => res);
    } catch (e) {
        console.error(e);
    }
};
