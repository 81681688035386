export const deleteData = (selectedTeam) => {
    return {
        team_name: selectedTeam.team_name,
        room_name: selectedTeam.room_name,
        location: selectedTeam.location,
    };
};

export const updateTeamName = (selectedTeam, newTeamName) => {
    return {
        old_team_name: selectedTeam.team_name,
        new_team_name: newTeamName,
        room_name: selectedTeam.room_name,
        location: selectedTeam.location,
    };
};

export const DATA = {
    viewModes: [1, 2, 3, 4, 5],
    layouts: ["sec_groove", "pri_groove", "diamond", "default"],
    statuses: ["enabled", "disabled"],
    timers: [10000, 15000, 20000, 30000],
};
