import "./index.css";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import { createEpicMiddleware } from "redux-observable";
import reducer from "./models/reducers";
import rootEpic from "./models/epics";
import Application from "./components/Application";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme";

const epicMiddleware = createEpicMiddleware();

const store = createStore(reducer, compose(applyMiddleware(epicMiddleware)));

epicMiddleware.run(rootEpic);

const App = () => (
    <ThemeProvider theme={theme}>
        <Provider store={store}>
            <Application />
        </Provider>
    </ThemeProvider>
);

export default App;
