import { useState, useCallback } from "react";
import { DayPicker, addToRange } from "react-day-picker";
import PropTypes from "prop-types";
import "react-day-picker/dist/style.css";
import "./style.css";

const Calendar = ({
    numberOfMonths = 1,
    singleDate,
    client,
    sendData,
    fetchAppointments,
}) => {
    const getInitialState = () => ({
        from: undefined,
        to: undefined,
    });

    const [dateRangeState, setDateRangeState] = useState({
        ...getInitialState(),
        isLoaded: false,
        items: undefined,
        client: client,
    });

    const arrayMix = (dateObj) => {
        const year = dateObj.getFullYear();
        const month = dateObj.getMonth() + 1;
        const day = dateObj.getDate();
        return [year, month, day].join("-");
    };

    const handleDayClick = useCallback(
        (day) => {
            if (singleDate) {
                const range = addToRange(day);
                setDateRangeState((prevState) => ({
                    ...prevState,
                    from: range.from,
                    to: range.from,
                }));
            } else {
                const range = addToRange(day, dateRangeState);
                setDateRangeState((prevState) => ({
                    ...prevState,
                    ...range,
                }));
            }
        },
        [singleDate, dateRangeState]
    );

    const handleResetClick = useCallback(() => {
        setDateRangeState((prevState) => ({
            ...prevState,
            ...getInitialState(),
        }));
    }, []);

    const SubmitButton = useCallback(
        (e) => {
            e.preventDefault();

            if (fetchAppointments) {
                let data = {
                    customerID: client,
                    startDate: arrayMix(dateRangeState.from),
                    endDate: arrayMix(dateRangeState.to),
                };
                fetchAppointments(data);
            } else {
                let data = {
                    customerID: client,
                    date: arrayMix(dateRangeState.from),
                };
                sendData(data);
            }
            setDateRangeState((prevState) => ({
                ...prevState,
                ...getInitialState(),
            }));
        },
        [
            client,
            dateRangeState.from,
            dateRangeState.to,
            sendData,
            fetchAppointments,
        ]
    );

    const { from, to } = dateRangeState;
    const modifiers = {
        start: from,
        end: to,
    };

    return (
        <div className='RangeExample'>
            <p>
                {!from && !to && "Please select the first day."}
                {from && !to && "Please select the last day."}
                {from &&
                    to &&
                    `Selected from ${arrayMix(from)} to ${arrayMix(to)}`}
            </p>
            <DayPicker
                numberOfMonths={numberOfMonths}
                selected={[from, { from, to }]}
                modifiersClassNames={{
                    start: "range-start",
                    end: "range-end",
                }}
                modifiers={modifiers}
                onDayClick={handleDayClick}
            />
            {from && to && (
                <div className='calendar__btn__container'>
                    <button
                        className='link'
                        onClick={SubmitButton}>
                        Submit
                    </button>
                    <button
                        className='link'
                        onClick={handleResetClick}>
                        Reset
                    </button>
                </div>
            )}
        </div>
    );
};

Calendar.propTypes = {
    numberOfMonths: PropTypes.number,
    singleDate: PropTypes.bool,
    client: PropTypes.string,
    sendData: PropTypes.func,
    fetchAppointments: PropTypes.func,
};

export default Calendar;
