export const handleUsernameChange = async (
    event,
    setNewValue,
    setError,
    boards
) => {
    const newValue = event.target.value;
    setNewValue(newValue);
    if (newValue === "") {
        setError("Username shouldn't be empty");
    } else if (newValue.length < 5 || newValue.length > 15) {
        setError("Username must be between 5 and 15 characters long");
    } else if (newValue.includes(" ")) {
        setError(`Username shouldn't contain spaces`);
    } else if (boards.some((loc) => loc.username === newValue)) {
        setError(`Username already exists`);
    } else {
        setError("");
    }
};

export const handlePasswordChange = async (
    event,
    setNewValue,
    setError,
    setIsSaveButtonEnabled,
    newUsername
) => {
    const newValue = event.target.value;
    setNewValue(newValue);
    if (newValue === "") {
        setError("Password shouldn't be empty");
        setIsSaveButtonEnabled(false);
    } else if (newValue.length < 5 || newValue.length > 15) {
        setError("Password must be between 5 and 15 characters long");
        setIsSaveButtonEnabled(false);
    } else if (newValue.includes(" ")) {
        setError(`Password shouldn't contain spaces`);
        setIsSaveButtonEnabled(false);
    } else if (newUsername === "") {
        setIsSaveButtonEnabled(false);
    } else {
        setError("");
        setIsSaveButtonEnabled(true);
    }
};

/**
 * @name handleLocationChange
 * @param {*} event
 * @param {*} setNewValue
 * @param {*} setError
 * @param {*} setIsSaveButtonEnabled
 * @param {*} boards
 * @description -> validates the new Location input and renders errors if conditions are not met
 */

export const handleLocationChange = (
    event,
    setNewValue,
    setError,
    setIsSaveButtonEnabled,
    boards
) => {
    const newValue = event.target.value;
    setNewValue(newValue);

    const letterRegex = /^[a-zA-Z]{3,20}$/;

    if (!letterRegex.test(newValue)) {
        setError(
            "Location should contain only letters (a-zA-Z) not spaces and should be 3 to 20 characters long."
        );
    } else if (boards.some((loc) => loc.location === newValue)) {
        setError("Location already exists");
    } else {
        setError("");
        setIsSaveButtonEnabled(true);
        return;
    }

    setIsSaveButtonEnabled(false);
};
