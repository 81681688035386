import { connect } from "react-redux";
import "./appointments.scss";
import PropTypes from "prop-types";

const Appointments = ({
    appointments,
    test,
    totalPlayers,
    lastSync,
    lastSyncTime,
    totalTeams,
}) => {
    /***
     * Ugly patch Clean up function for inappropriate DB room names
     *  */
    let Appointments = [...Object.entries(appointments)];

    console.log(Appointments);
    // Find Corrupt RoomNames

    /**
     * WALKING_DEATH / WALKINGDEATH
     * LA_MISSION_DEL_PROFESOR / LMDP
     * HORROR_CIRCUS / CIRCUS
     */
    const WALKINGDEATH = Appointments.find(
        (entry) => entry[0] === "WALKINGDEATH"
    );
    const opt_death = Appointments.find((apt) => apt[0] === "WALKING_DEATH");

    const LMDP = Appointments.find((entry) => entry[0] === "LMDP");
    const opt_lmdp = Appointments.find(
        (apt) => apt[0] === "LA_MISSION_DEL_PROFESOR"
    );

    const HORROR_CIRCUS = Appointments.find(
        (entry) => entry[0] === "HORROR_CIRCUS"
    );
    const opt_circus = Appointments.find((apt) => apt[0] === "CIRCUS");

    // Remove one instance if both instances are retrived

    if (opt_death) {
        Appointments = Appointments.filter(
            (appointment) => appointment[0] !== "WALKINGDEATH"
        );
    }
    if (opt_lmdp) {
        Appointments = Appointments.filter(
            (appointment) => appointment[0] !== "LMDP"
        );
    }
    if (opt_circus) {
        Appointments = Appointments.filter(
            (appointment) => appointment[0] !== "HORROR_CIRCUS"
        );
    }
    // Create new vars
    const WALKING_DEATH = ["WALKING_DEATH", { numberOfPlayers: 0, teams: 0 }];
    const CIRCUS = ["CIRCUS", { numberOfPlayers: 0, teams: 0 }];
    const LA_MISSION_DEL_PROFESOR = [
        "LA_MISSION_DEL_PROFESOR",
        { numberOfPlayers: 0, teams: 0 },
    ];

    let unique = [...Appointments];
    /**
     * CLEAN UP DATA
     */
    if (opt_death && WALKINGDEATH !== undefined) {
        unique = unique.filter((entry) => entry[0] !== "WALKING_DEATH");
        // assign num of players
        let num = WALKINGDEATH[1].numberOfPlayers;
        // Join num of players
        WALKING_DEATH[1].numberOfPlayers = opt_death[1].numberOfPlayers + num;
        // Join num of teams

        num = WALKINGDEATH[1].teams;
        WALKING_DEATH[1].teams = opt_death[1].teams + num;

        unique.push(WALKING_DEATH);
    }
    if (opt_lmdp && LMDP !== undefined) {
        unique = unique.filter(
            (entry) => entry[0] !== "LA_MISSION_DEL_PROFESOR"
        );

        let num = LMDP[1].numberOfPlayers;
        // Join num of players
        LA_MISSION_DEL_PROFESOR[1].numberOfPlayers =
            opt_lmdp[1].numberOfPlayers + num;
        // Join num of teams

        num = LMDP[1].teams;
        LA_MISSION_DEL_PROFESOR[1].teams = opt_lmdp[1].teams + num;

        unique.push(LA_MISSION_DEL_PROFESOR);
    }
    if (opt_circus && HORROR_CIRCUS !== undefined) {
        unique = unique.filter((entry) => entry[0] !== "HORROR_CIRCUS");
        unique = unique.filter((entry) => entry[0] !== "CIRCUS");
        let num = HORROR_CIRCUS[1].numberOfPlayers;
        // Join num of players
        HORROR_CIRCUS[1].numberOfPlayers = opt_circus[1].numberOfPlayers;
        CIRCUS[1].numberOfPlayers += num;
        // Join num of teams
        num = HORROR_CIRCUS[1].teams;
        CIRCUS[1].teams = opt_circus[1].teams;
        CIRCUS[1].teams += num;

        unique.push(CIRCUS);
    }

    // console.log({ unique })
    return (
        <div className='appointments__container'>
            {lastSync && <h3>Last sync Date: {lastSync}</h3>}
            {lastSyncTime && <h3>Last sync Time: {lastSyncTime}</h3>}
            {unique.map((item, index) => {
                let imgSrc;
                const imageName = item[0].toUpperCase().replace(/_/g, "");
                try {
                    imgSrc = require(`../../../assets/room_logos/${imageName}.png`);
                } catch (error) {
                    console.error(`${imageName}.png not found`);
                }

                return (
                    <div
                        className='appointments__boxes'
                        key={index}>
                        <div className='appointments__content'>
                            <div className='appointments__logoRoom'>
                                <img
                                    width='auto'
                                    height='auto'
                                    src={imgSrc}
                                    alt={`${item[1]}`}
                                />
                            </div>
                            <div className='appointments__players'>
                                <p>
                                    Players:
                                    <span> {item[1].numberOfPlayers}</span>
                                </p>
                            </div>
                            <div className='appointments__teams'>
                                <p>
                                    Teams:{" "}
                                    <span>{item[1].appointmentCount}</span>
                                </p>
                            </div>
                            {item.hour && (
                                <div className='appointments__bookings'>
                                    <p>Bookings: </p>
                                    <div className='appointments__bookings_container'>
                                        {item.hour.map((item, index) => (
                                            <span key={index}>
                                                {item.ts} - {item.players}{" "}
                                                players
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                );
            })}
            <div className='appointments__total'>
                <p>
                    Total teams: <span>{totalTeams}</span>
                </p>
                <p>
                    Total players: <span>{totalPlayers}</span>
                </p>
            </div>
            {test && (
                <div className='appointments__test'>
                    <p>
                        Test:{" "}
                        <span style={{ color: "#da1a35" }}>{test.test}</span>
                    </p>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    appointments: state.appointments,
    test: state.test,
    lastSync: state.userLastSync,
    lastSyncTime: state.userLastSyncTime,
    totalPlayers: state.totalPlayers,
    totalTeams: state.totalTeams,
});
Appointments.propTypes = {
    appointments: PropTypes.object,
    test: PropTypes.object,
    totalPlayers: PropTypes.number,
    lastSync: PropTypes.string,
    lastSyncTime: PropTypes.string,
    totalTeams: PropTypes.number,
};

export default connect(mapStateToProps, null)(Appointments);
