import { Accordion, AccordionSummary } from "@mui/material";
import { ExpandMoreRounded } from "@mui/icons-material";
import PropTypes from "prop-types";

const SettingsContainer = (props) => {
    return (
        <div>
            <Accordion disabled={props.isDisabled}>
                <AccordionSummary
                    expandIcon={<ExpandMoreRounded />}
                    aria-controls='panel1a-content'
                    id='panel1a-header'>
                    <h5 className='text-center'>{props.title}</h5>
                </AccordionSummary>
                {props.children}
            </Accordion>
        </div>
    );
};

SettingsContainer.propTypes = {
    isDisabled: PropTypes.bool,
    title: PropTypes.string,
    children: PropTypes.node,
};

export default SettingsContainer;
