import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableHead,
    TableContainer,
    AccordionDetails,
    Card,
} from "@mui/material";
import PropTypes from "prop-types";

/**
 * @name SettingsTable
 * @return -> SettingsTable accordians
 *
 */
const SettingsTable = (props) => (
    <AccordionDetails>
        <Card
            className='w-100'
            sx={{ minWidth: 275 }}>
            <TableContainer>
                <Table
                    sx={{ minWidth: 650 }}
                    aria-label='simple table'>
                    <TableHead>
                        <TableRow>
                            {props.tableHeaders.map((header, index) => (
                                <TableCell
                                    align={
                                        props.alignment && index !== 0
                                            ? "right"
                                            : "left"
                                    }
                                    key={index}>
                                    {header}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>{props.children}</TableBody>
                </Table>
            </TableContainer>
        </Card>
    </AccordionDetails>
);

SettingsTable.propTypes = {
    tableHeaders: PropTypes.arrayOf(PropTypes.string),
    alignment: PropTypes.bool,
    children: PropTypes.node,
};

export default SettingsTable;
