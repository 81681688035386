import PropTypes from "prop-types";
import "./index.scss";

const HeaderText = ({ title, caption }) => {
    return (
        <>
            <h2 className='header'>Hi {title.toUpperCase()}!</h2>
            <h3 className='caption'>{caption.toUpperCase()}</h3>
        </>
    );
};

HeaderText.propTypes = {
    title: PropTypes.string,
    caption: PropTypes.string,
};

export default HeaderText;
