import SettingsTable from "../SettingsContainer/SettingsTable";
import { Button, TableCell, TableRow } from "@mui/material";
import { useState, useEffect } from "react";
import { fetchAllUsers } from "../../services/autohelp";
import { UsersGenTableHeaders } from "../../constants";
import CreateUserModal from "../../models/CreateUserModal";
import ConfirmDeleteModal from "../../models/ConfirmTeamDeleteModal";
import PasswordDisplay from "../PasswordDisplay/PasswordDisplay";

const UsersGenTable = () => {
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [isToEdit, setIsToEdit] = useState(false);
    const [isToDeleteUser, setIsToDeleteUser] = useState(false);
    const [showCreateUserModal, setShowCreateUserModal] = useState(false);
    const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);

    const getUsers = async () => {
        setUsers(await fetchAllUsers());
    };

    const isAdminUser = (username) => username === "gameoveradmin";

    useEffect(() => {
        getUsers();
    }, []);

    const handleEditModal = () => {
        setIsToEdit(true);
        setShowCreateUserModal(!showCreateUserModal);
    };

    const handleDeleteUserModal = () => {
        setIsToDeleteUser(!isToDeleteUser);
        setShowDeleteUserModal(!showDeleteUserModal);
    };

    const handleCreateUserModal = () => {
        setIsToEdit(false);
        setShowCreateUserModal(!showCreateUserModal);
    };

    const handlePasswordVisibility = (user) => {
        setSelectedUser(user);
    };

    return (
        <SettingsTable
            tableHeaders={UsersGenTableHeaders}
            alignment={false}>
            {users.map((user, index) => (
                <TableRow
                    onClick={() => setSelectedUser(user)}
                    key={index}>
                    <TableCell
                        component='th'
                        scope='row'>
                        {user.username}
                    </TableCell>
                    <TableCell
                        component='th'
                        scope='row'>
                        <PasswordDisplay
                            clientId={user.id}
                            password={user.password}
                            isSelected={
                                selectedUser !== null &&
                                user.id === selectedUser.id
                            }
                            togglePasswordVisibility={handlePasswordVisibility}
                        />
                    </TableCell>
                    <TableCell
                        component='th'
                        scope='row'>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={() => handleEditModal()}
                            style={{ margin: "0% 30% 0% -27%" }}
                            disabled={isAdminUser(user.username)}>
                            Edit
                        </Button>
                        <Button
                            onClick={() => handleDeleteUserModal()}
                            variant='contained'
                            color='secondary'
                            disabled={isAdminUser(user.username)}>
                            Delete
                        </Button>
                    </TableCell>
                </TableRow>
            ))}
            <div
                className='create_user_modal'
                onClick={handleCreateUserModal}>
                CREATE USER
            </div>
            <CreateUserModal
                open={showCreateUserModal}
                getUsers={getUsers}
                handleClose={isToEdit ? handleEditModal : handleCreateUserModal}
                users={users}
                isToEdit={isToEdit ? isToEdit : false}
                selectedUser={selectedUser}
            />
            <ConfirmDeleteModal
                open={showDeleteUserModal}
                handleClose={handleDeleteUserModal}
                selectedUser={selectedUser}
                isToDeleteUser={isToDeleteUser}
                getUsers={getUsers}
            />
        </SettingsTable>
    );
};

export default UsersGenTable;
